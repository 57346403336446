<template>
  <div class="common-banner">
    <img :src="banner" />
    <div class="text-box">
      <div class="base-width">
        <div class="en">{{ en }}</div>
        <div class="ch">{{ ch }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["banner", "en", "ch"],
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1080px) {
  img {
    width: 100%;
  }

  .common-banner {
    position: relative;
  }

  .text-box {
    width: 100%;
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    top: 180px;
    left: 0;
  }
}

@media screen and (max-width: 1080px) {
  img {
    width: 100%;
    height: 200px;
  }

  .common-banner {
    position: relative;
  }

  .text-box {
    width: 100%;
    font-size: 26px;
    line-height: 40px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    top: 62px;
  }
}
</style>
