// 常用工具
const _toString = Object.prototype.toString;

/**
 * Perform no operation.
 */
export function noop() { }

// these helpers produces better vm code in JS engines due to their
// explicitness and function inlining
export function isUndef(v) {
  return v === undefined || v === null;
}

export function isDef(v) {
  return v !== undefined && v !== null;
}

export function isFunction(obj) {
  return typeof obj === 'function';
}

export function isNumber(obj) {
  let type = typeof obj;
  return (type === 'number' || type === 'string') && !isNaN(obj - parseFloat(obj));
}

export function type(obj) {
  return _toString.call(obj);
}

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

/**
 * Strict object type check. Only returns true
 * for plain JavaScript objects.
 */
export function isPlainObject(obj) {
  return type(obj) === '[object Object]';
}

export function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

export function isRegExp(v) {
  return type(v) === '[object RegExp]';
}

export function isDate(v) {
  return type(v) === '[object Date]';
}

// 格式化时间为苹果系统可兼容的格式
export function fixDate(date) {
  if (typeof date === 'string') {
    return date.replace(/-/g, '/').replace(/(\+\d{2})(\d{2})$/, '$1:$2');
  }
  return date;
}
// 格式化日期
export function formatDate1(fmt, date) {
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    "S": date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
  }
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    }
  return fmt
}

// 时间格式化
// format(new Date(), 'yyyy-MM-dd hh:mm:ss.S') => 2006-07-02 08:09:04.423
// format(new Date(), 'yyyy-M-d h:m:s.S')      => 2006-7-2 8:9:4.18
// format(new Date(), 'yyyy-MM-dd hh:mm:ss')   => 2006-07-02 08:09:04
export function formatDate(date = new Date(), fmt = 'yyyy-MM-dd') {
  if (!date) return date;
  if (typeof date === 'string') date = fixDate(date);
  const time = new Date(date);
  if (!isDate(time)) return date;
  let o = {
    'M+': time.getMonth() + 1, // 月
    'd+': time.getDate(), // 日
    'h+': time.getHours(), // 小时
    'm+': time.getMinutes(), // 分
    's+': time.getSeconds(), // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
    S: time.getMilliseconds(), // 毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }

  return fmt;
}
// 服务类型转换
export function transToTypeName(typeList, type) {
  if (!typeList || typeList.length < 1 || !type) {
    return '';
  }
  for (let i in typeList) {
    if (type == typeList[i].value) {
      return typeList[i].label;
    }
  }
};
// 获取三天前的日期
export function getThreeDaysBefore(date = new Date(), fmt = 'yyyy-MM-dd') {
  if (!date) return date;
  if (typeof date === 'string') date = fixDate(date);
  const time = new Date(date);
  if (!isDate(time)) return date;
  let o = {
    'M+': time.getMonth() + 1, // 月
    'd+': time.getDate() - 2, // 日
    'h+': time.getHours(), // 小时
    'm+': time.getMinutes(), // 分
    's+': time.getSeconds(), // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
    S: time.getMilliseconds(), // 毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }

  return fmt;
}
export const generateEnumOptions = (prefix, baseData) => {
  prefix = prefix.toUpperCase();

  const ret = {
    [`${prefix}_OPTIONS`]: [],
    [`${prefix}_ENUM`]: {},
    [`${prefix}_DESC`]: {},
  };

  for (const { value, label, enumKey } of baseData) {
    // options
    ret[`${prefix}_OPTIONS`].push({
      value,
      label,
    });
    // enum
    ret[`${prefix}_ENUM`][enumKey.toUpperCase()] = value;
    // key
    ret[`${prefix}_DESC`][value] = label;
  }

  return ret;
};

// 滚动素处，element:目标元素
export const scrollIntoView = (element) => {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
