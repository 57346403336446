import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';
Vue.use(VueRouter);
//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}


const routes = [
  {
    path: "/",
    component: () => import("@/views/home/Home.vue"),
  },
  {
    path: "/home",
    component: () => import("@/views/home/Home.vue"),
    breadcrumbName: '首页'
  },
  {
    path: "/questionnaire",
    component: () => import("@/views/questionnaire/Questionnaire.vue"),
    meta: {
      title: '服务',
    },
    breadcrumbName: '服务体系'
  },
  {
    path: "/service",
    component: () => import("@/views/service/Service.vue"),
    meta: {
      title: '服务',
    },
    breadcrumbName: '服务体系'
  },
  {
    //公共服务
    path: "/servicePublic",
    component: () => import("@/views/service/ServicePublic.vue"),
    breadcrumbName: '公共服务'
  },
  {
    //产业大数据
    path: "/serviceIndustry",
    component: () => import("@/views/service/ServiceIndustry.vue"),
    breadcrumbName: '产业大数据'
  },
  {
    //跨境物流
    path: "/serviceExport",
    component: () => import("@/views/service/ServiceExport.vue"),
    breadcrumbName: '跨境物流'
  },
  {
    //跨境物流详情
    path: "/serviceExportDetail",
    name: "serviceExportDetail",
    component: () => import("@/views/service/ServiceExportDetail.vue"),
    breadcrumbName: '跨境物流详情'
  },
  {
    // 金融财税
    path: "/serviceFinance",
    component: () => import("@/views/service/ServiceFinance.vue"),
    breadcrumbName: '金融财税'
  },
  {
    //海贸贷详情
    path: "/serviceFinanceHmd",
    component: () => import("@/views/service/ServiceFinanceHmd.vue"),
    breadcrumbName: '海贸贷'
  },
  {
    //保险详情
    path: "/serviceFinanceDetail",
    component: () => import("@/views/service/ServiceFinanceDetail.vue"),
    breadcrumbName: '保险详情'
  },
  {
    // 海外仓
    path: "/serviceOverSea",
    component: () => import("@/views/service/ServiceOverSea.vue"),
    breadcrumbName: '海外仓服务'
  },
  {
    // 海外仓详情
    path: "/serviceOverSeaDetail",
    component: () => import("@/views/service/ServiceOverSeaDetail.vue"),
    breadcrumbName: '海外仓详情'
  },
  {
    // 培训机构
    path: "/serviceTrain",
    component: () => import("@/views/service/ServiceTrain.vue"),
    breadcrumbName: '培训孵化'
  },
  {
    // 培训机构详情
    path: "/serviceTrainDetail",
    component: () => import("@/views/service/ServiceTrainDetail.vue"),
    breadcrumbName: '详情'
  },
  {
    // 数字营销
    path: "/serviceNumber",
    component: () => import("@/views/service/ServiceNumber.vue"),
    breadcrumbName: '数字营销'
  },
  {
    // 数字营销详情
    path: "/serviceNumberDetail",
    component: () => import("@/views/service/ServiceNumberDetail.vue"),
    breadcrumbName: '数字营销详情'
  },
  {
    // 信息技术服务
    path: "/serviceTechnology",
    component: () => import("@/views/service/ServiceTechnology.vue"),
    breadcrumbName: '信息技术服务'
  },
  {
    // 知识产权&法律
    path: "/serviceKnowledge",
    component: () => import("@/views/service/ServiceKnowledge.vue"),
    breadcrumbName: '知识产权与法律'
  },
  {
    // 知识产权详情
    path: "/serviceKnowledgeDetail",
    component: () => import("@/views/service/ServiceKnowledgeDetail.vue"),
    breadcrumbName: '详情'
  },
  {
    // 法律服务
    path: "/serviceLow",
    component: () => import("@/views/service/ServiceLow.vue"),
    breadcrumbName: '法律'
  },
  {
    // 法律服务详情
    path: "/serviceLowDetail",
    component: () => import("@/views/service/ServiceLowDetail.vue"),
    breadcrumbName: '法律详情'
  },
  {
    path: "/supply",
    component: () => import("@/views/supply/Supply.vue"),
    breadcrumbName: '供应市场'
  },
  {
    path: "/supplyDetail",
    component: () => import("@/views/supply/SupplyDetail.vue"),
  },
  {
    path: "/recent",
    component: () => import("@/views/recent/Recent.vue"),
    breadcrumbName: '近期活动'
  },
  {
    path: "/recentDetail",
    component: () => import("@/views/recent/RecentDetail.vue"),
    name:'活动详情'
  },
  {
    path: "/news",
    component: () => import("@/views/news/News.vue"),
    breadcrumbName: '新闻公告'
  },
  {
    path: "/newsDetail",
    component: () => import("@/views/news/NewsDetail.vue"),
    breadcrumbName: '新闻详细',
    name:'新闻详细'
  },
  {
    path: "/advice",
    component: () => import("@/views/advice/Advice.vue"),
    breadcrumbName: '投诉与建议'
  },
  {
    path: "/demand",
    component: () => import("@/views/demand/Demand.vue"),
    breadcrumbName: '需求市场'
  },
  {
    path: "/demandDetail",
    component: () => import("@/views/demand/DemandDetail.vue"),
  },
  {
    path: "/product",
    component: () => import("@/views/product/Product.vue"),
    breadcrumbName: '江苏优品'
  },
  {
    path: "/productDetail",
    component: () => import("@/views/product/ProductDetail.vue"),
  },
  {
    path: "/serviceComprehensive",
    component: () => import("@/views/service/ServiceComprehensive.vue"),
    breadcrumbName: '综合服务'
  },
  {
    path: "/serviceComprehensiveDetail",
    component: () => import("@/views/service/ServiceComprehensiveDetail.vue"),
    breadcrumbName: '综合服务详情'
  },
  {
    path: "/serviceComprehensiveSzgDetail",
    component: () => import("@/views/service/ServiceComprehensiveSzgDetail.vue"),
    breadcrumbName: '综合服务详情'
  },
  {
    path: "/login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/retrieve",
    component: () => import("@/views/retrieve/Retrieve.vue"),
    breadcrumbName: ''
  },
  {
    path: "/enroll",
    component: () => import("@/views/enroll/Enroll.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mySelf/phoneMy",
    component: () => import("@/views/mySelf/phoneMy/PhoneMy.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mySelf",
    component: () => import("@/components/MySelfBox.vue"),
    breadcrumbName: '',
    redirect: "/mySelf/base",
    children: [
      {
        path: "/mySelf/base",
        component: () => import("@/views/mySelf/base/Base.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  企业信息  ·  基础资料'
      },
      {
        path: "/mySelf/passwordRevise",
        component: () =>
          import("@/views/mySelf/passwordRevise/PasswordRevise.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  企业信息  ·  密码修改'
      },
      {
        path: "/mySelf/publishService",
        component: () => import("@/views/mySelf/publishService/PublishService.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  发布服务'
      },
      {
        path: "/mySelf/publishServiceDetail",
        component: () =>
          import("@/views/mySelf/publishService/PublishServiceDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  服务详情'
      },
      {
        path: "/mySelf/publishDetail",
        component: () =>
          import("@/views/mySelf/overseaDetail/OverseaDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  海外仓详情'
      },
      {
        path: "/mySelf/publishRecent",
        component: () => import("@/views/mySelf/publishRecent/PublishRecent.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  发布活动'
      },
      {
        path: "/mySelf/publishRecentDetail",
        component: () => import("@/views/mySelf/publishRecent/PublishRecentDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  活动详情'
      },
      {
        path: "/mySelf/publishDemand",
        component: () => import("@/views/mySelf/publishDemand/PublishDemand.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  发布需求'
      },
      {
        path: "/mySelf/publishDemandDetail",
        component: () => import("@/views/mySelf/publishDemand/PublishDemandDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  需求详情'
      },
      {
        path: "/mySelf/publishProduct",
        component: () => import("@/views/mySelf/publishProduct/PublishProduct.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  发布商品'
      },
      {
        path: "/mySelf/publishProductDetail",
        component: () => import("@/views/mySelf/publishProduct/PublishProductDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的发布  ·  商品详情'
      },
      {
        path: "/mySelf/complaintAdvice",
        component: () => import("@/views/mySelf/complaintAdvice/ComplaintAdvice.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  投诉建议'
      },
      {
        path: "/mySelf/policyReport",
        component: () => import("@/views/mySelf/policyReport/PolicyReport.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  政策申报'
      },
      {
        path: "/mySelf/policyReportDetail",
        component: () => import("@/views/mySelf/policyReport/PolicyReportDetail.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  申报详情'
      },
      {
        path: "/mySelf/oversea",
        component: () => import("@/views/mySelf/oversea/Oversea.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的海外仓'
      },
      {
        path: "/mySelf/overseaDetail",
        component: () =>
          import("@/views/mySelf/overseaDetail/OverseaDetail.vue"),
        breadcrumbName: ''
      },
      {
        path: "/mySelf/message",
        component: () => import("@/views/mySelf/message/Message.vue"),
        breadcrumbName: '',
        name: '供应商中心  ·  我的消息',
      },
      {
        path: "/mySelf/declare",
        component: () => import("@/views/mySelf/declare/DeclareDataList.vue"),
        breadcrumbName: '',
        name: '数据申报',
      },
      {
        path: "/mySelf/declareDetail",
        component: () => import("@/views/mySelf/declare/DeclareDataDetail.vue"),
        breadcrumbName: '',
        name: '数据申报详情',
      },
    ],
  },
  {
    path: "/mobile/",
    component: () => import("@/views/mobile/index.vue"),
  },
  {
    path: "/mobile/login",
    component: () => import("@/views/mobile/login.vue"),
    breadcrumbName: '',
    name: '手机登录',
  },
  {
    path: "/mobile/home",
    component: () => import("@/views/mobile/index.vue"),
    breadcrumbName: '',
    name: '手机端首页',
  },
  {
    path: "/mobile/forgetPassword",
    component: () => import("@/views/mobile/forgetPassword.vue"),
    breadcrumbName: '',
    name: '手机端忘记密码',
  },
  {
    path: "/mobile/serve",
    component: () => import("@/views/mobile/serve.vue"),
    breadcrumbName: '',
    name: '手机端服务体系',
  },
  {
    path: "/mobile/servicePublic",
    component: () => import("@/views/mobile/service/ServicePublic.vue"),
    breadcrumbName: '',
    name: '手机端服务体系',
  },
  {
    //产业大数据
    path: "/mobile/serviceIndustry",
    component: () => import("@/views/mobile/service/ServiceIndustry.vue"),
    breadcrumbName: ''
  },
  {
    //跨境物流
    path: "/mobile/serviceExport",
    component: () => import("@/views/mobile/service/ServiceExport.vue"),
    breadcrumbName: ''
  },
  {
    //跨境物流详情
    path: "/mobile/serviceExportDetail",
    name: "serviceExportDetail",
    component: () => import("@/views/mobile/service/ServiceExportDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 金融财税
    path: "/mobile/serviceFinance",
    component: () => import("@/views/mobile/service/ServiceFinance.vue"),
    breadcrumbName: ''
  },
  {
    //海贸贷详情
    path: "/mobile/serviceFinanceHmd",
    component: () => import("@/views/mobile/service/ServiceFinanceHmd.vue"),
    breadcrumbName: ''
  },
  {
    //保险详情
    path: "/mobile/serviceFinanceDetail",
    component: () => import("@/views/mobile/service/ServiceFinanceDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 海外仓
    path: "/mobile/serviceOverSea",
    component: () => import("@/views/mobile/service/ServiceOverSea.vue"),
    breadcrumbName: ''
  },
  {
    // 海外仓详情
    path: "/mobile/serviceOverSeaDetail",
    component: () => import("@/views/mobile/service/ServiceOverSeaDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 培训机构
    path: "/mobile/serviceTrain",
    component: () => import("@/views/mobile/service/ServiceTrain.vue"),
    breadcrumbName: ''
  },
  {
    // 培训机构详情
    path: "/mobile/serviceTrainDetail",
    component: () => import("@/views/mobile/service/ServiceTrainDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 数字营销
    path: "/mobile/serviceNumber",
    component: () => import("@/views/mobile/service/ServiceNumber.vue"),
    breadcrumbName: ''
  },
  {
    // 数字营销详情
    path: "/mobile/serviceNumberDetail",
    component: () => import("@/views/mobile/service/ServiceNumberDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 信息技术服务
    path: "/mobile/serviceTechnology",
    component: () => import("@/views/mobile/service/ServiceTechnology.vue"),
    breadcrumbName: ''
  },
  {
    // 知识产权&法律
    path: "/mobile/serviceKnowledge",
    component: () => import("@/views/mobile/service/ServiceKnowledge.vue"),
    breadcrumbName: ''
  },
  {
    // 知识产权详情
    path: "/mobile/serviceKnowledgeDetail",
    component: () => import("@/views/mobile/service/ServiceKnowledgeDetail.vue"),
    breadcrumbName: ''
  },
  {
    // 法律服务
    path: "/mobile/serviceLow",
    component: () => import("@/views/mobile/service/ServiceLow.vue"),
    breadcrumbName: ''
  },
  {
    // 法律服务详情
    path: "/mobile/serviceLowDetail",
    component: () => import("@/views/mobile/service/ServiceLowDetail.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/serviceComprehensiveDetail",
    component: () => import("@/views/mobile/service/ServiceComprehensiveDetail.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/serviceComprehensiveSzgDetail",
    component: () => import("@/views/mobile/service/ServiceComprehensiveSzgDetail.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/serviceComprehensive",
    component: () => import("@/views/mobile/service/ServiceComprehensive.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/serviceComprehensiveDetail",
    component: () => import("@/views/mobile/service/ServiceComprehensiveDetail.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/provide",
    component: () => import("@/views/mobile/provide.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/provideDetail",
    component: () => import("@/views/mobile/provideDetail.vue"),
  },
  {
    path: "/mobile/market",
    component: () => import("@/views/mobile/market.vue"),
    breadcrumbName: ''
  },
  {
    path: "/mobile/marketDetail",
    component: () => import("@/views/mobile/marketDetail.vue"),
  },
  {
    path: "/mobile/center",
    component: () => import("@/views/mobile/center.vue"),
  },
  {
    path: "/mobile/myOversea",
    component: () => import("@/views/mobile/myOversea.vue"),
    breadcrumbName: '',
    name: '我的海外仓',
  },
  {
    path: "/mobile/center",
    component: () => import("@/views/mobile/center.vue"),
    breadcrumbName: '',
    name: '企业中心',
  },
  {
    path: "/mobile/myPublish",
    component: () => import("@/views/mobile/myPublish.vue"),
    breadcrumbName: '',
    name: '我的发布',
  },
  {
    path: "/mobile/myMessage",
    component: () => import("@/views/mobile/myMessage.vue"),
    breadcrumbName: '',
    name: '我的消息',
  },
  {
    path: "/mobile/policy",
    component: () => import("@/views/mobile/policy.vue"),
    breadcrumbName: '',
    name: '政策申报',
  },
  {
    path: "/mobile/recent",
    component: () => import("@/views/mobile/recent.vue"),
    breadcrumbName: '',
    name: '近期活动',
  },
  {
    path: "/mobile/news",
    component: () => import("@/views/mobile/News.vue"),
    breadcrumbName: '',
    name: '新闻公告',
  },
  {
    path: "/mobile/newsDetail",
    component: () => import("@/views/mobile/NewsDetail.vue"),
    breadcrumbName: '',
    name: '新闻详细',
  },
  {
    path: "/mobile/recentDetail",
    component: () => import("@/views/mobile/RecentDetail.vue"),
    breadcrumbName: '',
    name: '新闻详细',
  },
  
];

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  let {
    requireAuth
  } = to.meta;
  let {
    isLogin
  } = store.getters;
  // 没登录，进入需要登录的页面跳回首页，提示登录
  if (requireAuth && !isLogin) {
    Vue.prototype.$message.error('请登录后查看!');
    next('/');
  }
  next();
});
export default router;
