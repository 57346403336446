<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <div class="float flex justify-between">
        <div v-if="$route.name == '新闻详细' || $route.name == '活动详情' || $route.name == ''" @click="showModal">
        <div class="imb-box" style="color: #5e5959">
          <img class="chat" src="@/assets/image/char.svg" />
        </div>
      </div>
        <!-- <div>
          <div class="imb-box" v-if="img1" @click="img1 = !img1">
            <img src="@/assets/image/home/23.png" />
          </div>
          <div class="imb-box te" v-else @click="img1 = !img1">
            <img src="@/assets/image/home/24.png" />
          </div>
        </div>
        <div>
          <div class="imb-box" v-if="img2" @click="img2 = !img2">
            <img src="@/assets/image/home/25.png" />
          </div>
          <div class="imb-box te" v-else @click="img2 = !img2">
            <img src="@/assets/image/home/26.png" />
          </div>
        </div>
        <div>
          <div class="imb-box" v-if="img3" @click="img3 = !img3">
            <img src="@/assets/image/home/27.png" />
          </div>
          <div class="imb-box te" v-else @click="img3 = !img3">
            <img src="@/assets/image/home/28.png" />
          </div>
        </div> -->
      </div>
      <a-modal
        :confirm-loading="confirmLoading"
        :footer="null"
        :visible="visible"
        title="打开微信“扫一扫”，分享给朋友和朋友圈"
        @cancel="handleCancel"
      >
      <div style="text-align: center">
        <p id="qrcode" class="qr" style="text-align: center"></p>
      </div>
    </a-modal>
      <!-- 添加key是为了解决页面中使用this.$router.push地址栏变了但页面没有跳转的问题 -->
      <router-view :key="$route.fullPath" />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      locale: zhCN,
      img1: true,
      img2: true,
      img3: true,
      visible: false,
      confirmLoading: false
    };
  },
  mounted() {
    //console.log(this.$route.name);
   },
  methods: {
    showModal() {
      this.visible = true;
      this.$nextTick(()=>{
          document.getElementById('qrcode').innerHTML=''
          //console.log('this.$refs.qrcodeRef=', this.$refs.qrcodeRef.innerHTML)
          var title = window.location.href
          var qrcode = new QRCode('qrcode', {
            text: title, // 需要转换为二维码的内容
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
    },
    handleCancel() {
      this.visible = false;
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },


};
</script>
<style scoped lang="scss">
@media screen and (max-width: 1080px) {
  .float {
    display: none;
  }
}

.float {
  flex-direction: column;
  position: fixed;
  right: 110px;
  bottom: 180px;
  z-index: 9999;

  .imb-box {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(4, 0, 0, 0.15);

    img {
      width: 28px;
      height: 28px;
    }
  }

  .te {
    background: #009ada;
  }
}
.qr {
  margin: auto;
  width: 150px;
  height: 180px;
  text-align: center;
  margin-bottom: 40px;
}
</style>
