import { getUserInfo } from '@/api';
import router from '@/router';
// import { getToken, removeToken } from '@/tools/token';

export default {
  state: {
    token: '',
    company: '',
    companyName: '',
    userName: '',
    userNo: '',
    isLogin: false,
    UserInfo: JSON.parse(localStorage.getItem('user')) || {},
    isAdmin: false,
    isNormalEnterprise: false,
    isServerEnterprise: false,
    isWareEnterprise: false,
  },

  mutations: {
    setUserInfo(state, resultData) {
      state.UserInfo = resultData;
      localStorage.setItem('user', JSON.stringify(resultData));
      state.token = resultData.accessToken;
      state.userName = resultData.userName;
      state.userNo = resultData.userNo;
      state.company = resultData.company;
      state.companyName = resultData.companyName;
      state.isAdmin = resultData.extData.isAdmin;
      state.isNormalEnterprise = resultData.extData.isNormalEnterprise;
      state.isServerEnterprise = resultData.extData.isServerEnterprise;
      // state.isServerEnterprise = true;
      state.isWareEnterprise = resultData.extData.isWareEnterprise;
      // state.isWareEnterprise = true;
    },
    removeUserInfo(state) {
      localStorage.removeItem('user');
      state.UserInfo = {};
      state.token = '';
    },
  },
  getters: {
    userName: (state) => state.userName,
    userNo: (state) => state.userNo,
    token: (state) => state.token,
    company: (state) => state.company,
    companyName: (state) => state.companyName,
    isLogin: (state) => (state.token ? true : false),
    UserInfo: (state) => state.UserInfo,
    // 是否是管理员账号（企业）
    isAdmin: (state) => state.isAdmin,
    // 是否普通企业
    isNormalEnterprise: (state) => state.isNormalEnterprise,
    // 是否服务商企业
    isServerEnterprise: (state) => state.isServerEnterprise,
    // 是否海外仓企业
    isWareEnterprise: (state) => state.isWareEnterprise,

  },
  actions: {
    async loginOut({ commit }) {
      commit('removeUserInfo');
      router.push('/');
    },
    async setUserInfo(content, payload) {
      content.commit('setUserInfo', payload);
    },
  },
};
