<template>
  <div>
    <div class="top flex justify-between">
      <div class="left">
        请点击
        <span class="pointer" style="text-decoration: underline" @click="gotoQuestionnaire">问卷链接</span>
      </div>
    </div>
    <!-- <div class="goBack" v-if="$route.path.includes('mySelf')" @click="$router.back()">
              <a-icon type="ArrowLeftOutlined "></a-icon>
              返回
            </div> -->
    <div class="three-line" @click="headerShow = !headerShow">
      <div :style="{ background: color }"></div>
      <div :style="{ background: color }"></div>
      <div :style="{ background: color }"></div>
    </div>
    <div class="url-list" @click="headerShow = !headerShow" v-if="headerShow">
      <div class="item" :class="$route.path == '/home' ? 'active' : ''" @click="jump('/home')">
        首页
      </div>
      <div @click="jump('/servicePublic')" :class="$route.path == '/servicePublic' ? 'active' : ''">
        公共服务
      </div>
      <div @click="jump('/serviceindustry')" :class="$route.path == '/serviceIndustry' ? 'active' : ''">
        产业大数据
      </div>
      <div @click="jump('/serviceExport')" :class="$route.path == '/serviceExport' ? 'active' : ''">
        跨境物流
      </div>
      <div @click="jump('/serviceFinance')" :class="$route.path == '/serviceFinance' ? 'active' : ''">
        金融财税
      </div>
      <div @click="jump('/serviceOverSea')" :class="$route.path == '/serviceOverSea' ? 'active' : ''">
        海外仓服务
      </div>
      <div @click="jump('/serviceTrain')" :class="$route.path == '/serviceTrain' ? 'active' : ''">
        培训孵化
      </div>
      <div @click="jump('/serviceNumber')" :class="$route.path == '/serviceNumber' ? 'active' : ''">
        数字营销
      </div>
      <div @click="jump('/serviceKnowledge')" :class="$route.path == '/serviceKnowledge' ? 'active' : ''">
        知识产权&法律
      </div>
      <div @click="jump('/serviceTechnology')" :class="$route.path == '/serviceTechnology' ? 'active' : ''">
        信息技术服务
      </div>
      <div @click="jump('/serviceComprehensive')" :class="$route.path == '/serviceComprehensive' ? 'active' : ''">
        综合服务
      </div>
      <div class="item" @click="jump('/supply')" :class="$route.path == '/supply' ? 'active' : ''">
        供应市场
      </div>
      <div class="item" @click="jump('/demand')" :class="$route.path == '/demand' ? 'active' : ''">
        需求市场
      </div>
      <div class="item" @click="jump('/recent')" :class="$route.path == '/recent' ? 'active' : ''">
        近期活动
      </div>
      <div class="item" @click="jump('/news')" :class="$route.path == '/news' ? 'active' : ''">
        新闻公告
      </div>
      <div class="item" @click="jump('/advice')" :class="$route.path == '/advice' ? 'active' : ''">
        投诉建议
      </div>
      <div class="item" @click="jump()">关于平台</div>
    </div>
    <div class="header flex align-center justify-between base-width">
      <div class="left flex align-center">
        <img class="logo" src="@/assets/image/home/1.png" />
        <div class="item-box flex">
          <div class="item" :class="$route.path == '/home' ? 'active' : ''" @click="jump('/home')">
            首页
          </div>
          <div class="item">
            <div :class="$route.path.includes('service') ? 'active' : ''">
              服务体系
            </div>
            <div class="child">
              <div class="child-l">
                <div @click="jump('/servicePublic')" :class="$route.path == '/servicePublic' ? 'active' : ''">
                  公共服务
                </div>
                <div @click="jump('/serviceIndustry')" :class="$route.path == '/serviceIndustry' ? 'active' : ''">
                  产业大数据
                </div>
                <div @click="jump('/serviceExport')" :class="$route.path == '/serviceExport' ? 'active' : ''">
                  跨境物流
                </div>
                <div @click="jump('/serviceFinance')" :class="$route.path == '/serviceFinance' ? 'active' : ''">
                  金融财税
                </div>
                <div @click="jump('/serviceOverSea')" :class="$route.path == '/serviceOverSea' ? 'active' : ''">
                  海外仓服务
                </div>
              </div>
              <div class="child-r">
                <div @click="jump('/serviceTrain')" :class="$route.path == '/serviceTrain' ? 'active' : ''">
                  培训孵化
                </div>
                <div @click="jump('/serviceNumber')" :class="$route.path == '/serviceNumber' ? 'active' : ''">
                  数字营销
                </div>
                <div @click="jump('/serviceKnowledge')" :class="$route.path == '/serviceKnowledge' ? 'active' : ''">
                  知识产权&法律
                </div>
                <div @click="jump('/serviceTechnology')" :class="$route.path == '/serviceTechnology' ? 'active' : ''">
                  信息技术服务
                </div>
                <div @click="jump('/serviceComprehensive')"
                     :class="$route.path == '/serviceComprehensive' ? 'active' : ''">
                  综合服务
                </div>
              </div>
            </div>
          </div>
          <div class="item" @click="jump('/supply')" :class="$route.path == '/supply' ? 'active' : ''">
            供应市场
          </div>
          <div class="item" @click="jump('/demand')" :class="$route.path == '/demand' ? 'active' : ''">
            需求市场
          </div>
          <div class="item" @click="jump('/recent')" :class="$route.path == '/recent' ? 'active' : ''">
            近期活动
          </div>
          <div class="item" @click="jump('/product')" :class="$route.path == '/product' ? 'active' : ''">
            江苏优品
          </div>
          <div class="item" @click="jump('/news')" :class="$route.path == '/news' ? 'active' : ''">
            新闻公告
          </div>
          <div class="item" @click="jump('/advice')" :class="$route.path == '/advice' ? 'active' : ''">
            投诉建议
          </div>
          <!-- <div class="item" @click="jump()">关于平台</div> -->
        </div>
      </div>
      <div class="right flex align-center">
        <div class="login flex justify-center align-center" v-if="!isLogin">
          <div class="pointer" @click="jump('/login')">登录</div>
          <a-divider type="vertical" class="login-divider"></a-divider>
          <div class="pointer" @click="jump('/enroll')">注册</div>
        </div>
        <div class="login flex justify-center align-center pointer" @click="$router.push('/mySelf/base')" v-else>
          HI,{{ userName }}
          <div class="loginOut" @click.stop="logout">退出登录</div>
        </div>
        <!-- <img class="search pointer" src="@/assets/image/home/2.png" /> -->
      </div>
    </div>
  </div>
</template>

<script>


// import { ArrowLeftOutlined } from '@ant-design/icons-vue'
export default {
  props: {
    color: {
      default: "#fff",
    },
  },
  data() {
    return {
      headerShow: false,
      isLogin: false,
      userName: '',
      questionnaire: {}
    };
  },
  created() {
    this.isLogin = this.$store.getters.isLogin;
    this.userName = this.$store.getters.userNo;
  },
  methods: {
    gotoQuestionnaire() {
      this.$api.getQuestionnaireTemplate().then(res => {
        if (res.success) {
          this.questionnaire = res.data;
          this.$router.push({ path: this.questionnaire.url, query: { name: this.questionnaire.title } })
        } else {
          this.$message.warning(res.message)
        }
      }, () => {
      });
    },
    jump(val) {
      this.$router.push(val);
    },
    logout() {
      this.$store.dispatch('loginOut');
      const currPath = this.$route.path;
      if (currPath == '/' || currPath == '/home') {
        this.$router.go(0);
      } else {
        this.$router.push("/home");
      }
    }
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 1080px) {
  .top {
    padding: 0 6px;
    border-bottom: 1px solid #d2d2d2;
    color: #161616;
    display: block;
    display: none;

    .left {
      box-sizing: border-box;
      background-size: cover;
      font-size: 13px;
      font-weight: 400;
      line-height: 30px;
    }

    .right {
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .goBack {
    position: absolute;
    z-index: 9999999999;
    left: 4%;
    top: 22px;
    font-size: 14px;
    line-height: 18px;
  }

  .three-line {
    position: absolute;
    z-index: 9999999999;
    right: 4%;
    top: 20px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    >div {
      width: 24px;
      height: 2px;
      background: #ffffff;
    }
  }

  .header {
    display: none;
  }

  .url-list {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 18px;

    >div {
      color: #999999;
      margin: 4px;
    }

    .active {
      color: #fff;
    }
  }
}

@media screen and (min-width: 1080px) {
  .goBack {
    position: absolute;
    z-index: 9999999999;
    left: 4%;
    top: 22px;
    font-size: 14px;
    line-height: 18px;
  }

  .url-list {
    display: none !important;
  }

  .top {
    border-bottom: 1px solid #d2d2d2;

    .left {
      box-sizing: border-box;
      width: 735px;
      height: 47px;
      background: url("~@/assets/image/home/top.png") no-repeat center;
      background-size: cover;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      padding-left: 362px;
      line-height: 47px;
    }

    .right {
      font-size: 12px;
      font-weight: 400;
      color: #161616;
      line-height: 47px;
      padding-right: 362px;
    }
  }

  .header {
    .left {
      .logo {
        width: 194px;
        height: 51px;
        margin-right: 10px;
      }

      .item-box {
        font-size: 16px;
        font-weight: bold;
        color: #1a213b;
        line-height: 80px;

        .item {
          margin-right: 20px;
          position: relative;
          cursor: pointer;

          &:hover {
            &:after {
              position: absolute;
              content: "";
              width: 100%;
              height: 3px;
              bottom: 0;
              left: 0;
              background: #009ada;
            }

            .child {
              height: 243px;
            }
          }

          .child {
            overflow: hidden;
            width: 367px;
            //height: 243px;
            height: 0px;
            box-sizing: border-box;
            z-index: 99999;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 80px;
            left: 0;
            transition: height 0.4s;
            background: #fff;
            font-size: 16px;
            font-weight: bold;
            color: #27303f;
            line-height: 36px;

            .child-l {
              margin-right: 74px;

              >div {
                display: flex;
                position: relative;
              }
            }

            .child-r {
              >div {
                display: flex;
                position: relative;
              }
            }
          }
        }

        .active {
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 3px;
            bottom: 0;
            left: 0;
            background: #009ada;
          }
        }
      }
    }

    .right {
      .login {
        position: relative;
        width: 125px;
        background: #fe9900;
        border-radius: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #1a213b;
        line-height: 43px;

        &:hover {
          .loginOut {
            height: 43px;
          }
        }

        .loginOut {
          z-index: 99999;
          border-radius: 22px;
          position: absolute;
          left: 0;
          width: 100%;
          text-align: center;
          line-height: 43px;
          cursor: pointer;
          background: #fff;
          height: 0;
          top: 43px;
          transition: height 0.4s;
          overflow: hidden;
        }
      }

      .login-divider {
        border-left-color: #1a213b;
        background: #1a213b;
      }

      img {
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
    }
  }
}
</style>
