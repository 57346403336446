<template>
  <div>
    <div class="foot">
      <div class="foot-t">
        指导单位：苏州市商务局 | 苏州高新区商务局&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        建设单位：苏州海贸通跨境电子商务产业服务有限公司
      </div>
      <div class="foot-c">
        组织单位： 市商务局 | 市财政局 |
        市市场监督管理局 | 苏州海关 | 国家外汇管理局苏州市分局 |
        国家税务总局苏州市税务局 | 市邮政管理局
      </div>
      <div class="foot-b">备案号：苏ICP备2023006083号-1</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1080px) {
  .foot {
    font-size: 14px;
    font-weight: 400;
    color: #f0f9f8;
    line-height: 30px;
    padding: 46px 0 54px;
    background: #232f3f;
    text-align: center;
  }
}

@media screen and (max-width: 1080px) {
  .foot {
    font-size: 14px;
    font-weight: 400;
    color: #f0f9f8;
    line-height: 30px;
    padding: 46px 0 54px;
    background: #232f3f;
    text-align: center;
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
}
</style>
