/**
 * 接口模板用法：
 * import { test } from '@/api'
 * test(params).then(res => console.log(res))
 */
import http from '../tools';

const baseUrl = '/cbe_portal';

export const login = (params) => http.post(`${baseUrl}/login/login`, params);
// 获取用户信息
export const getUserInfo = (params) => http.get(`${baseUrl}/user/getUserInfo?companyNo=` + params);
// 注册
export const register = (params) => http.upload(`${baseUrl}/registerController/register`, params);
// 获取码表
export const selectByPcode = (params) => http.post(`${baseUrl}/dictionary/selectByPcodeWithLabelValue`, params);
// 获取码表
export const getSmsCode = (params) => http.post(`${baseUrl}/verify/getSmsCode`, params);
// 获取个人信息
export const getPersonalInfo = (params) => http.post(`${baseUrl}/registerController/getPersonalInfo`, params);
//修改个人信息
export const updateUserInfo = (params) => http.upload(`${baseUrl}/registerController/updateUserInfo`, params);
//修改密码
export const changePassword = (params) => http.post(`${baseUrl}/common/changePassword`, params);
//忘记密码
export const forgetPassword = (params) => http.post(`${baseUrl}/common/forgetPassword`, params);

/*************跨境物流**************/
//根据类型获取企业列表
export const getEnterpriseList = (params) => http.post(`${baseUrl}/enterprise/getEnterpriseListByType`, params);
//根据id获取企业详情
export const getEnterpriseInfoById = (params) => http.get(`${baseUrl}/enterprise/getEnterpriseInfoById?id=` + params);
//获取海外仓服务列表
export const getOverSeaServiceList = (params) => http.get(`${baseUrl}/warehouse/getWareHouseList?flag=0`);
//获取海外仓详情
export const getOverSeaServiceDetail = (params) => http.get(`${baseUrl}/warehouse/getWareHouseDetail?id=` + params);

/***************海外仓*********************/
//我的海外仓列表查询
export const selectMyOverserPaged = (params) => http.post(`${baseUrl}/overseasWarehouse/selectMyOverserPaged`, params);
//海外仓详情
export const selectMyOverseaById = (params) => http.post(`${baseUrl}/overseasWarehouse/selectById`, params);
//查询国家
export const selectAllCountryVue = (params) => http.post(`${baseUrl}/common/selectAllCountryVue`, params);
//根据Id删除海外仓
export const deleteWarehouseById = (params) => http.post(`${baseUrl}/overseasWarehouse/deleteById`, params);
//根据Id发布海外仓
export const releaseOrInvalidWarehouseById = (params) => http.post(`${baseUrl}/overseasWarehouse/releaseOrInvalidById`, params);
//新增海外仓
export const insertWarehouse = (params) => http.upload(`${baseUrl}/overseasWarehouse/insertWarehouse`, params);
//修改海外仓
export const updateWarehouse = (params) => http.upload(`${baseUrl}/overseasWarehouse/updateWarehouse`, params);
/***************门户*********************/
// 发布供应
export const saveSupply = (params) => http.upload(`${baseUrl}/supply/save`, params);
// 获取供应详情
export const getSupplyDetail = (params) => http.get(`${baseUrl}/supply/getById?id=` + params);
// 获取供应列表
export const getSupplyList = (params) => http.post(`${baseUrl}/supply/list`, params);
// 获取所有已发布的供应列表
export const getAllSupplyList = (params) => http.post(`${baseUrl}/supply/publishedList`, params);
// 保存服务消息
export const saveSupplyMessage = (params) => http.post(`${baseUrl}/serveMessage/save`, params);
// 改变供应单状态
export const changeSupplyState = (params) => http.post(`${baseUrl}/supply/updateState`, params);
// 发布活动
export const saveEvent = (params) => http.upload(`${baseUrl}/event/save`, params);
// 获取活动详情
export const getEventDetail = (params) => http.get(`${baseUrl}/event/getById?id=` + params);
// 获取活动列表
export const getEventList = (params) => http.post(`${baseUrl}/event/list`, params);
// 改变活动状态
export const changeEventState = (params) => http.post(`${baseUrl}/event/updateState`, params);
// 根据活动id获取报名列表
export const getRegistListById = (params) => http.post(`${baseUrl}/eventRegist/getByEventId`, params);
// 导出表格
export const exportTable = (params) => http.downloadFile(`${baseUrl}/eventRegist/export?eventId=` + params);
// 发布需求
export const saveDemand = (params) => http.post(`${baseUrl}/demand/save`, params);
// 获取需求详情
export const getDemandDetail = (params) => http.get(`${baseUrl}/demand/selectById?id=` + params);
// 获取列表
export const getDemandList = (params) => http.post(`${baseUrl}/demand/list`, params);
// 改变需求状态
export const changeDemandState = (params) => http.post(`${baseUrl}/demand/updateState`, params);
// 查询供应国家集合
export const supplyCountryList = () => http.get(`${baseUrl}/supply/countryList`);
// 查询需求国家集合
export const demandCountryList = () => http.get(`${baseUrl}/demand/countryList`);
// 获取所有国家集合
export const listForCountry = () => http.get(`${baseUrl}/dictionary/listForCountry`);
// 保存海贸贷信息
export const saveSeaTradeLoan = (params) => http.post(`${baseUrl}/seaTradeLoan/save`, params);
// 保存活动报名信息
export const saveEventEnrollInfo = (params) => http.post(`${baseUrl}/eventRegist/save`, params);
// 保存投诉建议信息
export const saveComplaintOrSuggestInfo = (params) => http.post(`${baseUrl}/complaintSuggestion/save`, params);
// 获取投诉回复的列表
export const getComSugList = (params) => http.post(`${baseUrl}/complaintSuggestion/list`, params)
// 个人中心获取我的消息
export const getMessageList = (params) => http.post(`${baseUrl}/serveMessage/list`, params);
// 更改消息状态
export const changeMessageState = (params) => http.post(`${baseUrl}/serveMessage/changeState`, params)
// 政策申报上传文件
export const uploadFile = (params) => http.upload(`${baseUrl}/policyDeclareFile/uploadFile`, params);
// 政策申报下载文件
export const downloadFile = (params) => http.downloadFile(`${baseUrl}/policyDeclareFile/download?fileId=` + params);
// 政策申报删除文件
export const delFile = (params) => http.post(`${baseUrl}/policyDeclareFile/delFile`, params);
// 保存政策申报
export const savePolicy = (params) => http.post(`${baseUrl}/policyDeclare/save`, params);
// 政策申报列表
export const getPolicyDeclareList = (params) => http.post(`${baseUrl}/policyDeclare/list`, params);
// 政策申报详情
export const getPolicyDetail = (params) => http.get(`${baseUrl}/policyDeclare/getDetail?id=` + params);
// 政策申报提交
export const changePolicyStatus = (params) => http.post(`${baseUrl}/policyDeclare/updateStatus`, params);
// 根据code获取系统配置项列表
export const getConfigList = (param) => http.get(`${baseUrl}/dictionary/getConfigList/` + param)
// 保存商品
export const saveProduct = (param) => http.upload(`${baseUrl}/product/save`, param);
// 获取商品详情
export const getProductDetail = (params) => http.get(`${baseUrl}/product/selectById?id=` + params);
// 获取查询列表
export const getProductList = (params) => http.post(`${baseUrl}/product/list`, params);
// 首页获取商品列表
export const productList = (params) => http.post(`${baseUrl}/product/selectAll`, params);
// 商品发布
export const changeProductState = (params) => http.post(`${baseUrl}/product/updateState`, params);
// 获取中心风采视频地址
export const getCentralStyle = () => http.get(`${baseUrl}/centralStyle/selectValid`);
/***************门户首页*********************/
//获取新闻公告
export const getHomeNews = (params) => http.post(`${baseUrl}/policyConsultation/getHomeNews`, params);
// 获取统计数据
export const getStatisticsData = () => http.post(`${baseUrl}/common/statistics`);

/***************新闻公告*********************/
//获取列表
export const selectNewsPaged = (params) => http.post(`${baseUrl}/policyConsultation/selectNewsPaged`, params);
//获取明细
export const selectNewsById = (params) => http.post(`${baseUrl}/policyConsultation/selectById`, params);

/***************数据申报*********************/
//获取列表
export const selectDeclareDataPaged = (params) => http.post(`${baseUrl}/DeclareData/selectDeclareDataPaged`, params);
//根据ID删除申报数据
export const deleteDeclareDataById = (params) => http.post(`${baseUrl}/DeclareData/deleteById`, params);
//根据ID查询申报数据
export const selectDeclareDataById = (params) => http.get(`${baseUrl}/DeclareData/getDeclareDataDetail`, params);
//修改申报数据
export const insertOrupdateDeclareDataDetail = (params) => http.post(`${baseUrl}/DeclareData/insertOrupdateDeclareDataDetail`, params);
//提交申报数据
export const releaseDeclareDataDetail = (params) => http.post(`${baseUrl}/DeclareData/releaseDeclareDataDetail`, params);
//立即发布或取消发布
export const changeDeclareStatus = (params) => http.post(`${baseUrl}/DeclareData/changeStatus`, params);
/***************问题调查*********************/
// 保存问卷调查
export const saveQuestionnaire = (params) => http.post(`${baseUrl}/questionnaire/survey/save`, params);
// 获取问卷调查模板
export const getQuestionnaireTemplate = () => http.get(`${baseUrl}/questionnaire/survey/getTemplate`)
/************手机端***********/
//我的消息数量
export const countMessageNum = (params) => http.post(`${baseUrl}/serveMessage/countMessageNum`, params);
// 获取微信用户
export const getWeChatUser = (params) => http.get(`${baseUrl}/wechat/getWeChatUser?openId=` + params);

/*****************太仓跨境电商*******************************/
// 登录
export const pspLogin = (params) => http.post(`${baseUrl}/ThirdPartService/pspLogin`,params);

