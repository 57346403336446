<template>
  <div class="route base-width">
    <div style="width:90%;float:left">
      您的当前所在位置：&nbsp;
      <span v-for="(item, index) in route" :key="index" @click="gotoRouter(item)" style="cursor: pointer;">
        <span v-if="index">&nbsp;·&nbsp;</span>
        {{ item }}
      </span>
    </div>
    <div class="goBack" v-if="$route.path.includes('mySelf')" @click="$router.back()">
      <a-icon type="arrow-left"></a-icon>
      返回
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      default: () => {
        return [];
      },
    },
  },
  methods: {
    gotoRouter(item) {
      if (this.route.indexOf(item) >= this.route.length - 1) {
        return;
      }
      var pagePath = '/';
      var rous = this.$router.options.routes;
      for (let i in rous) {
        if (rous[i].breadcrumbName) {
          if (rous[i].breadcrumbName == item) {
            pagePath = rous[i].path
          }
        }
      }
      this.$router.push(pagePath)
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1080px) {
  .route {
    font-size: 16px;
    font-weight: 400;
    color: #5e5959;
    line-height: 16px;
    opacity: 0.61;
    margin-top: 24px;
    display: flex;

    .goBack {
      width: 6%;
      float: right;
      top: 22px;
      font-size: 16px;
      line-height: 18px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1080px) {
  .route {
    display: none;
    font-size: 12px;
    font-weight: 400;
    color: #5e5959;
    line-height: 16px;
    opacity: 0.61;
    margin-top: 24px;
  }
}
</style>
