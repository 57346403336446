import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import * as api from './api';
import { sync } from 'vuex-router-sync';
//main.js
//引入flexible
import "amfe-flexible/index";
Vue.config.productionTip = false;
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "./scss/base.scss";
import Header from "@/components/Header.vue";
import Foot from "@/components/Foot.vue";
import Banner from "@/components/Banner.vue";
import Route from "@/components/Route.vue";
import MobileHead from "@/views/mobile/mobileHead.vue";
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
import LocaleProvider from 'ant-design-vue'
import echarts from 'echarts'
import 'echarts/map/js/china'
import qrcode from "qrcodejs2"

Vue.use(LocaleProvider)
Vue.prototype.$moment = moment;//挂载到当前vue实例对象
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Header", Header);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Foot", Foot);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Banner", Banner);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Route", Route);
Vue.component("MobileHead", MobileHead);
function buildsystem() {
  let { VUE_APP_PUBLIC_PATH } = process.env;
  let configPath = "config.json";
  if (VUE_APP_PUBLIC_PATH) configPath = VUE_APP_PUBLIC_PATH + configPath;

  axios.get(configPath).then((res) => {
    Vue.prototype.Baseurl = res.data.Baseurl;
    //console.log(res.data.Baseurl)
    if (Vue.prototype.Baseurl) {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app');
    }
  });
}
Vue.use(Antd);
Vue.config.productionTip = false;
Vue.prototype.$api = api; // api暴露在vue对象中
Vue.prototype.$echarts = echarts
sync(store, router);
buildsystem();
