import * as until from './until';
import { ajax, get, downloadFile, post, del, put, upload, xhrs, upParams, uploadFile } from './http';
let wjtools = {
  // 通用工具
  until,
  // ajax请求
  ajax,
  get,
  downloadFile,
  post,
  del,
  put,
  upload,
  uploadFile,
  xhrs,
  upParams,
};

// 初始化公用函数
wjtools.upParams();
export default wjtools;
