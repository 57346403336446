import axios from 'axios';
import store from '../store/index';
// import config from '../config'
import Vue from 'vue';
export let xhrs = [];
axios.defaults.timeout = 21000;
let V = new Vue();
// 请求报错后重试的次数
axios.defaults.retry = 1;
axios.defaults.withCredentials = true;

// 请求出错后重试的次数
axios.defaults.retryDelay = 1000;
// axios.defaults.baseURL = config.api;
// document.cookie = 'currToken=B0291031-C6CE-4AB3-9DCC-FAF8E803FCE4'

// 更新通用参数
export function upParams() {
  // let params = this.params
  // params.token = store.userInfo.token
  // return params
}
let pendingXhrs = []; // 声明一个数组用于存储每个ajax请求 的 取消函数和标识
let removePending = (config, type) => {
  for (let n in pendingXhrs) {
    if (pendingXhrs[n].flag === config.method + ':' + config.ur) {
      type !== 'response' && pendingXhrs[n].abort(); // 执行取消操作
      pendingXhrs.splice(n, 1); // 删除此条请求记录
    }
  }
};
let CancelToken = axios.CancelToken;
// let currentToken = document.cookie
axios.interceptors.request.use(
  (config) => {
    if (config.push === '/enroll' || config.push === '/') {
    } else {
      //todo 设置token
      if (localStorage.getItem('user')) {
        config.headers.token = store.getters.UserInfo.accessToken;
      }
      // config.headers.token = store.getters.UserInfo.token || ''; // 不返回token了，取消携带，后端校验登录不根据这个来
      config.headers['Access-Control-Allow-Origin'] = true;
      config.headers.withCredentials = true;
      // 在一个ajax 发送前取消一个重复请求
      if (config.abortPrev) {
        removePending(config);
        config.CancelToken = new CancelToken((c) => {
          pendingXhrs.push({
            flag: config.method + ':' + config.ur,
            abort: c,
          });
        });
      }

    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 添加拦截响应器
axios.interceptors.response.use(
  (response) => {
    let { status = '', data = {} } = response;
    let { code = '' } = data;
    if (['2405', '2461', '2101', '2110',401].includes(code)) {
      store.dispatch('loginOut');
      V.$message.error('登录过期，请重新登录!');
      return Promise.reject('登录过期，请重新登录!');
    }
    return Promise.resolve(response);
  },
  (error) => {
    // if (error.config) {
    //   if (error.response?.data?.code == '2405') {
    //     localStorage.removeItem('user');
    //   }
    // }
    const status = (error.response && error.response.status && error.response.status) || '';
    const data = (error.response && error.response.data) || {};
    if (data.message) {
      V.$message.error(data.message);
      return Promise.reject(data.message);
    }

    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
      V.$message.error('请求超时，请重试!');
      return Promise.reject('请求超时，请重试!');
    }
    if (status === 404) {
      V.$message.error('接口404报错');
      return Promise.reject('接口404报错，请重试!');
    }
    if (status === 500) {
      V.$message.error('服务器错误');
      return Promise.reject('服务器错误，请重试!');
    }
    return Promise.reject('未知错误');
  },
);

// ajax请求封装
export function ajax(opts) {
  axios.defaults.baseURL =
    process.env.NODE_ENV == 'production' ? Vue.prototype.Baseurl : process.env.VUE_APP_API_PREFIX;
  // axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? Vue.prototype.szceb_Baseurl : '/szceb_admin'
  // axios.defaults.baseURL = Vue.prototype.dyck_Baseurl
  // axios.defaults.baseURL = '/dyck'
  return new Promise((resolve, reject) => {
    // let cachekey = opts.cachekey
    const handle = (res) => {
      let data = res.data || {};
      let status = data.status || data.code;
      let nologin = ['-1', '100000004', 401].includes(status);
      // let ok = ['1','200'].includes(status)
      if (nologin && !opts.notCheckLogin) {
        this.logout();
        this.login();
        return;
      }
      resolve(data);
    };
    let xhr = axios(
      Object.assign(opts, {
        url: opts.url,
        method: opts.method || opts.type || 'get',
        // baseUrl: this.config.api
        params: opts.params, // 请求参数
        data: opts.data, // 发送的主体数据，适用于post put patch
        resonseType: opts.resonseType || opts.dataType || 'json',
        headers: opts.headers || { 'content-type': 'application/json' },
        onUploadProgress: opts.onUploadProgress, // 文件上传进度
        timeout: opts.timeout || axios.defaults.timeout, // 请求超时指定时间
      }),
    )
      .then(handle)
      .catch((err) => {
        opts.error && opts.error(err);
        reject(err);
      });
    xhrs.push(xhr);
  });
}
// get 请求封装
export function get(url, params = {}, opts = {}) {
  Object.assign(opts, {
    url,
    params,
    method: 'get',
  });

  return ajax.call(this, opts);
}
// excel导出 请求封装
export function downloadFile(url, params = {}, opts = {}) {
  Object.assign(opts, {
    url,
    params,
    method: 'get',
    responseType: 'blob'
  });

  return ajax.call(this, opts);
}

// post 请求封装
export function post(url, data = {}, opts = {}) {
  Object.assign(opts, {
    url,
    data: data,
    method: 'post',
    headers: opts.headers || { 'content-type': 'application/json;charset=utf-8' },
  });
  return ajax.call(this, opts);
}

// del 请求封装
export function del(url, data = {}, opts = {}) {
  Object.assign(opts, {
    url,
    data: data,
    method: 'delete',
    headers: opts.headers || { 'content-type': 'application/json;charset=utf-8' },
  });

  return ajax.call(this, opts);
}

// put 请求封装
export function put(url, data = {}, opts = {}) {
  Object.assign(opts, {
    url,
    data: data,
    method: 'put',
    headers: opts.headers || { 'content-type': 'application/json;charset=utf-8' },
  });

  return ajax.call(this, opts);
}

// 文件上传-支持批量上传，参数示例：{file: file/fileList}
export function upload(url, data = {}, opts = {}) {
  let fd = new FormData();

  // 将参数与文件添加到 formData 对象中
  for (let [key, value] of Object.entries(data)) {
    if (key === 'file') {
      if (Array.isArray(value)) {
        value.forEach((item) => fd.append(item.name, item));
      } else {
        fd.append(value.name, value);
      }
    } else {
      if (Array.isArray(value)) {
        value.forEach((item) => fd.append(key, item));
      } else {
        fd.append(key, value);
      }
    }
  }

  Object.assign(opts, {
    url,
    // data: qs.stringify(data), // 将请求数据转换为 form-data 格式
    data: fd,
    method: 'post',
    // headers: opts.headers || {'content-type': 'multipart/multipart;charset=utf-8'}
    headers: opts.headers || { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' },
  });

  return ajax.call(this, opts);
}

// 文件上传-支持批量上传，参数示例：{file: file/fileList} java 方法
export function uploadFile(url, data = {}, opts = {}) {
  let fd = new FormData();

  // 将参数与文件添加到 formData 对象中
  for (let [key, value] of Object.entries(data)) {
    if (key === 'file') {
      if (Array.isArray(value)) {
        value.forEach((item) => fd.append('file', item));
      } else {
        fd.append('file', value);
      }
    } else {
      fd.append('file', value);
    }
  }
  Object.assign(opts, {
    url,
    // data: qs.stringify(data), // 将请求数据转换为 form-data 格式
    data: fd,
    method: 'post',
    // headers: opts.headers || {'content-type': 'multipart/multipart;charset=utf-8'}
    headers: opts.headers || { 'content-type': 'multipart/form-data' },
  });

  return ajax.call(this, opts);
}
